// Filename - App.js
import React from "react";
import "./App.css";
import Navbar from "./component/Navbar";
import Home from "./component/Home";
import BasicArea from "./component/BasicArea";


const App = () => {

	return (
		<>
			<Navbar/>
			<BasicArea/>
			<Home/>
		</>
	);
};

export default App;
