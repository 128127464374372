import React ,{ useState,useEffect } from "react";
import Papa from "papaparse";

export default function Home() {

    // This state will store the parsed data
	const [data, setData] = useState([]);

	const [rev, setRev] = useState("");

	// It state will contain the error when
	// correct file extension is not used
	const [error, setError] = useState("");

	// It will store the file uploaded by the user
	const [file, setFile] = useState("");

	// Allowed extensions for input file
    const allowedExtensions = ["csv"];

    // This function will be called when
	// the file input changes
	const handleFileChange = (e) => {
		setError("");

		// Check if user has entered the file
		if (e.target.files.length) {
			const inputFile = e.target.files[0];
      
      
			// Check the file extensions, if it not
			// included in the allowed extensions
			// we show the error
			const fileExtension =
				inputFile?.type.split("/")[1];
        //console.log(fileExtension);
			if (
				!allowedExtensions.includes(fileExtension)
			) {
				setError("Please input a csv file");
				return;
			}
      //console.log(inputFile);
			// If input type is correct set the state
			setFile(inputFile);
		}
	};
	const handleParse = () => {
	
		// If user clicks the parse button without
		// a file we show a error
		if (!file) return alert("Enter a valid file");

		// Initialize a reader which allows user
		// to read any file or blob.
		const reader = new FileReader();

		// Event listener on reader when the file
		// loads, we parse it and set the data.
		reader.onload = async ({ target }) => {
			const csv = Papa.parse(target.result, {
				header: true,
			});
			const parsedData = csv?.data;
			const rows = Object.keys(parsedData[0]);

			const columns = Object.values(parsedData[0]);
      //console.log(columns);
      
			const res = rows.reduce((acc, e, i) => {
				return [...acc, [[e], columns[i]]];
			}, []);
			//console.log(res);
			setData(res);
		};
		reader.readAsText(file);
	};

	

	useEffect(() => {
        const url = "https://wbes.wrldc.in/Report/GetCurrentDayFullScheduleMaxRev?regionid=2&ScheduleDate=15-12-2023";

        const fetchDatanew = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                //console.log(json.MaxRevision);
                setRev(json.MaxRevision);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchDatanew();
    }, []);

    return(
        <div className="App">
			<div className="container">
                <div className="mb-3">
                    <label htmlFor="revNo" className="form-label">Revision No:</label>
                    <input type="number" className="form-control" id="revNo" value={rev}/>
                </div>

                <select className="form-select" aria-label="Default select example">
                    <option >Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
                
				<label className="my-3"
					htmlFor="csvInput"
					style={{ display: "block" }}
				>
					Enter CSV File
				</label>
				<input
					onChange={handleFileChange}
					id="csvInput"
					name="file"
					type="File"
				/>
				<div>
					<button onClick={handleParse}>
						Submit
					</button>
				</div>
        
				<div style={{ marginTop: "3rem" }}>
					{error
						? error
						: data.map((e, i) => (
							<div key={i} className="item">
								{e[0]}:{e[1]}
							</div>
						))}
				</div>
			</div>
		</div>

    );
};