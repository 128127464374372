import React, { useState,useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

export default function BasicArea() {

  const [block, setBlock] = useState([]);
  const [onbar, setOnbar] = useState([]);
  const [offbar, setOffbar] = useState([]);
  const [untotalent, setUntotalent] = useState([]);
  const [totalent, setTotalent] = useState([]);
  //const [hat, setHat] = useState([]);		

	useEffect(() => {
        const url = "https://demo.tangerineservices.in/api/front/v1/demand/getReactDataTest";

        const fetchDataapi = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                
                setBlock(json.data.block);
                setOnbar(json.data.OnBarEnt);
                setOffbar(json.data.OffBarEnt);
                setUntotalent(json.data.UnregTotalEnt);
                setTotalent(json.data.TotalEnt);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchDataapi();
    }, []);

    /* console.log('this is block :: ', block);
    console.log('this is onbar:: ', onbar);
    console.log('this is offbar:: ', offbar);
    console.log('this is untotalent:: ', untotalent);
    console.log('this is totalent:: ', totalent); */

   /*  useEffect(() => {
      fetch('http://ebanzaro.com/api/articles/artslug/?code=e991decc6c3fdccedc1b4abfe97781a8&slug=baba-baidyanath-temple').then(data => data.json()).then(singleresult => {
        
        setHat(singleresult);
        
      }) 
    }, []); */

    //console.log('this is hat :: ', hat);

  const uData = onbar;
  const pData = offbar;
  const wData = untotalent;
  const xData = totalent;
  const xLabels = block;

  /* const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490, 3000];
  const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300, 2000];
  const wData = [2100, 1098, 9200, 3608, 5000, 4200, 4900, 1000];
  const xLabels = [
    'Page A',
    'Page B',
    'Page C',
    'Page D',
    'Page E',
    'Page F',
    'Page G',
    'Page H',
  ]; */

  return (
    <LineChart
      width={1000}
      height={400}
      series={[
        { data: pData, label: 'On Bar' },
        { data: uData, label: 'Off Bar' },
        { data: wData, label: 'Unreg Total Ent' },
        { data: xData, label: 'Total Ent' },
      ]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
    />
  );
}